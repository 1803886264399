<template>
	<div
		class="c-feedback-answers"
		:class="{
			'c-feedback-answers--no-spacing': noSpacing,
			'c-feedback-answers--left-aligned': leftAligned,
		}"
	>
		<div
			v-for="option in optionsArray"
			:key="option.sentiment"
			style="display: flex; flex-direction: column; align-items: center;"
		>
			<button
				class="c-feedback-answers__answer"
				:class="{
					'c-feedback-answers__answer--minimized': highlighted && option.sentiment !== highlighted,
					'c-feedback-answers__answer--inline-text': inlineText,
				}"
				type="button"
				:title="$t(option.textKey)"
				:aria-label="$t(option.textKey)"
				@click="onFeedback(option.sentiment)"
			>
				<span>{{ option.emoji }}</span>
				<span
					v-if="hasText"
					class="c-feedback-answers__answer-text"
				>{{ $t(option.textKey2) }}</span>
			</button>
		</div>
	</div>
</template>

<script>
	const options = {
		love: {
			textKey: 'satisfactionFeedback.love',
			textKey2: 'satisfactionFeedback.loveIt',
			sentiment: 'love',
			emoji: '😍',
		},
		like: {
			textKey: 'satisfactionFeedback.like',
			textKey2: 'satisfactionFeedback.likeIt',
			sentiment: 'like',
			emoji: '🙂',
		},
		neutral: {
			textKey: 'satisfactionFeedback.neutral',
			textKey2: 'satisfactionFeedback.neutral',
			sentiment: 'neutral',
			emoji: '😐',
		},
		dislike: {
			textKey: 'satisfactionFeedback.dislike',
			textKey2: 'satisfactionFeedback.dislikeIt',
			sentiment: 'dislike',
			emoji: '🙁',
		},
		hate: {
			textKey: 'satisfactionFeedback.hate',
			textKey2: 'satisfactionFeedback.hateIt',
			sentiment: 'hate',
			emoji: '😠',
		},
		yes: {
			textKey: 'satisfactionFeedback.yes',
			textKey2: 'satisfactionFeedback.yes',
			sentiment: 'yes',
			emoji: '👍',
		},
		no: {
			textKey: 'satisfactionFeedback.no',
			textKey2: 'satisfactionFeedback.no',
			sentiment: 'no',
			emoji: '👎',
		},
	};

	const {
		love, like, neutral, dislike, hate, yes, no,
	} = options;

	export default {
		name: 'FeedbackAnswers',
		props: {
			onFeedback: {
				type: Function,
			},
			noSpacing: {
				type: Boolean,
				default: false,
			},
			leftAligned: {
				type: Boolean,
				default: false,
			},
			moreAnswers: {
				type: Boolean,
				default: false,
			},
			inlineText: {
				type: Boolean,
				default: false,
			},
			yesNo: {
				type: Boolean,
				default: false,
			},
			highlighted: {
				type: String,
				default: null,
			},
			hasText: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			optionsArray() {
				if (this.yesNo) {
					return [yes, no];
				}

				if (this.moreAnswers) {
					return [hate, dislike, neutral, like, love];
				}
				return [dislike, neutral, like];
			},
		},
	};
</script>

<style scoped>
	.c-feedback-answers {
		--answer-padding: var(--spacing-8);
		--answer-margin: var(--spacing-2);
		gap: var(--feedback-answers-gap, 0);

		@media only screen and (min-width: 540px) {
			--answer-padding: var(--spacing-16);
			--answer-margin: var(--spacing-8);
		}

		display: flex;
		justify-content: center;
	}

	.c-feedback-answers--no-spacing {
		--answer-padding: 0;
		--answer-margin: 0;
	}

	.c-feedback-answers--left-aligned {
		justify-content: start;
		grid-gap: var(--spacing-16);
	}

	.c-feedback-answers__answer {
		cursor: pointer;
		font-size: var(--c-feedback-answer-font-size, 32px);
		background: transparent;
		border: none;
		padding: var(--answer-padding);
		margin: var(--answer-margin);
		transition: transform 200ms;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.c-feedback-answers__answer--inline-text {
		flex-direction: row;
		gap: var(--spacing-8);
	}

	.c-feedback-answers__answer--minimized {
		opacity: 0.5;
	}

	.c-feedback-answers__answer:hover {
		transform: scale(1.2);
	}

	.c-feedback-answers__answer-text {
		font-size: 14px;
		font-weight: bold;
		margin-top: var(--spacing-8);
	}

	.c-feedback-answers__answer--inline-text .c-feedback-answers__answer-text {
		margin-top: 0;
	}

	.c-feedback-answers__answer-text:first-letter {
		text-transform: uppercase;
	}
</style>
