<template>
	<div>
		<div v-if="searchAISuggestionsFeature">
			<h2>AI Suggested Flashcards</h2>
			<ul
				class="c-deck-info-cards-grid"
				style="padding: 0;"
			>
				<li
					v-for="(deck, index) in (streamedJSON.decks || [])"
					:key="index"
					class="c-deck-info-card"
				>
					<div class="c-deck-info-card__section">
						<div style="display: flex; justify-content: space-between; flex-direction: column; flex-grow: 1;">
							<span class="c-deck-info-card__title"><strong>{{ deck.title }}</strong></span>
							<div style="display: flex; flex-direction: column;">
								<p>{{ deck.description }}</p>
								<p>{{ deck.fieldOfStudy }}</p>
								<button
									style="align-self: end;"
									type="button"
									class="c-btn c-btn--cta c-btn--small"
								>
									Generate Deck
								</button>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<ul>
				<li
					v-for="suggestion in streamedJSON.suggestions"
					:key="suggestion"
				>
					{{ suggestion }}
				</li>
			</ul>
			<ul>
				<li
					v-for="(value, key) in streamedJSON.dataCollectedSoFar"
					:key="key"
				>
					{{ key }}: {{ value != null ? value : 'null' }}
				</li>
			</ul>
		</div>

		<DecksContainer
			:decks="decks"
			:title="$t('dashboard.searchResultsForQuery', { query })"
			:fetch-decks="fetchDecks"
			:request-more-decks="requestMoreDecks"
			:results-amount="resultsAmount"
		/>
		<div class="c-feedback">
			<h4
				class="ui header"
				style="display: inline-block; margin: 0; margin-right: var(--spacing-24);"
			>
				{{ $t('satisfactionFeedback.didYouFind') }}
			</h4>
			<FeedbackAnswers
				style="--feedback-answers-gap: 16px; --c-feedback-answer-font-size: auto;"
				yes-no
				has-text
				no-spacing
				inline-text
				:highlighted="this.sentiment"
				:on-feedback="feedback"
			/>
		</div>
	</div>
</template>
<script>
	import { fetchStream } from '@/assets/js/utils/fetchStream';
	import { mapGetters } from 'vuex';
	import DecksContainer from '@/components/dashboard/DecksContainer.vue';
	import Feedback from '@/components/feedback/Feedback.vue';
	import FeedbackAnswers from '@/components/feedback/FeedbackAnswers.vue';
	import axios from 'axios';

	export default {
		name: 'Search',
		components: {
			FeedbackAnswers,
			Feedback,
			DecksContainer,
		},
		data() {
			return {
				streamedJSON: {},
				sentiment: null,
			};
		},
		computed: {
			...mapGetters(['feature', 'userId']),
			searchAISuggestionsFeature() {
				return this.feature('searchAISuggestions');
			},
			resultsAmount() {
				return this.$store.getters.queryLists(this.query).length;
			},
			query() {
				return this.$route.query.query;
			},
			decks() {
				if (!this.query) {
					return [];
				}

				return this.$store.getters.queryLists(this.query);
			},
			requestMoreDecks() {
				return () => this.$store.dispatch('loadModeSearchDecks', this.query);
			},
		},
		watch: {
			query() {
				this.sentiment = null;
			},
		},
		async created() {
			if (this.searchAISuggestionsFeature) {
				const params = new URLSearchParams({
					query: this.query,
					amount: 6,
				});

				await fetchStream({ url: `/generator/create-deck-suggestions?${params}` }, ({ fixedJSON }) => {
					this.streamedJSON = fixedJSON;
				});
			}
		},
		methods: {
			async fetchDecks() {
				if (!this.$store.getters.queryContainsResults(this.query)) {
					await this.$store.dispatch('searchDecks', this.query);
				}
			},
			feedback(sentiment) {
				if (this.sentiment) {
					return;
				}

				const payload = {
					sentiment,
					date: new Date().toUTCString(),
					query: this.query,
					url: window.location.href,
					userid: this.userId,
					resultsAmount: this.resultsAmount,
				};

				axios.post('https://api.sheety.co/b6bf8f69a89018e2ec81df644d928e09/surveyResults/search', { search: payload });

				const message = this.$t('satisfactionFeedback.thankYouForYourFeedbackShort');
				this.$store.dispatch('setToastMessage', { message });

				this.sentiment = sentiment;
			},
		},
	};
</script>
<style scoped>
.c-feedback {
	align-items: center;
	padding: var(--spacing-16) var(--spacing-24);
	background: var(--grey-8);
	border-radius: var(--spacing-8);
	margin: var(--spacing-24) auto 0;
	justify-content: flex-start;
	display: flex;
	flex-direction: row;
}
</style>
